<template>
  <div>
    <v-row class="mt-0" v-if="newValue.length == 0">
      <v-col cols="12">
        <p class="mb-0">No state licenses on record.</p>
      </v-col>
    </v-row>
    <div v-else>
      <v-row v-for="(item, index) in newValue" :key="index" class="mt-0 d-flex align-center">
        <v-col sm="5" cols="12" class="py-0">
          <DynamicFormControl 
              :control="{
                  name: 'LicenseState_' + index,
                  type: 'Dropdown',
                  value: item.State,
                  config: {
                      label: 'License State',
                      rules: 'required',
                      options: licenseStates[index],
                      short: true,
                      clearable: true
                  }
              }"
              @change="updateLicense(item, 'State', $event)" 
          />
        </v-col>
        <v-col sm="6" cols="12" class="py-0">
          <DynamicFormControl 
              :control="{
                  name: 'LicenseNumber_' + index,
                  type: 'TextInput',
                  value: item.Number,
                  config: {
                      label: 'License Number',
                      rules: 'required',
                      short: true
                  }
              }"
              @change="updateLicense(item, 'Number', $event)" 
          />
        </v-col>
        <v-col sm="1" cols="12" class="py-0">
          <v-btn color="#BBBBBB" type="button" icon @click="removeLicense(index)" style="margin-top: -8px;" title="Remove License">
              <v-icon>fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    

    <v-row class="mt-4">
      <v-col cols="12" class="text-left">

        <div v-if="newValue.length >= max">
            <p class="mb-0">You have entered the maximum number of licenses ({{max}}).</p>
        </div>
        <div v-else>
          <v-btn color="white" type="button"  @click="addLicense">
            <i class="fas fa-plus-circle"></i>&nbsp;&nbsp;Add License
          </v-btn>
        </div>
        
      </v-col>
    </v-row>

  </div>

  
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import { StateNames } from '@/scripts/options';
import { newGuid } from '@/scripts/helper'

export default {
  name: 'StateLicenses',
  components: {
    DynamicFormControl
  },
  data() {
    return {
      newValue: [],
      licenseStates: [], //dropdowns per license line
      max: 30
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    'change'
  ],
  methods: {
    newGuid,
    addLicense() {
      this.newValue.push({
        State: null,
        Number: null
      }),
      this.updatedFilteredOptions();
      this.emitValue();
    },
    removeLicense(index) {
      this.newValue.splice(index, 1);
      
      this.updatedFilteredOptions();

      this.$forceUpdate();
    },
    updateLicense(item, key, event) {
      
      item[key] = event.value;
      
      if(key == "State") { //remove selected state from dropdown
        this.updatedFilteredOptions();
      }

      this.emitValue();
    },
    emitValue() {
      this.$emit('change', this.newValue);
    },
    updatedFilteredOptions()
    {
      var selected = this.newValue.map(val => val.State);
      this.licenseStates = this.newValue.map(val => StateNames.map(o => { return {
      text: o.name,
      value: o.abbreviation
      }})
      .filter(o => val.State == o.value || //value is selected
        (['AS', 'DC', 'FM', 'GU', 'MH', 'MP',  'PR', 'PW', 'VI'].indexOf(o.value) == '-1' && //exclude territories
        selected.indexOf(o.value) == '-1'))); //exclude other selected states
      
    }
  },
  mounted() {
    this.updatedFilteredOptions();
  },
  watch: {
      value: {
        handler(newVal) {
          if (newVal && newVal.length) {
              this.newValue = newVal;
          } else {
              this.newValue = [];
          }
          this.updatedFilteredOptions();
        },
        immediate: true,
        deep: true,
      }
  },
}
</script>
