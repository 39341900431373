var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.newValue.length == 0)?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0"},[_vm._v("No state licenses on record.")])])],1):_c('div',_vm._l((_vm.newValue),function(item,index){return _c('v-row',{key:index,staticClass:"mt-0 d-flex align-center"},[_c('v-col',{staticClass:"py-0",attrs:{"sm":"5","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'LicenseState_' + index,
                type: 'Dropdown',
                value: item.State,
                config: {
                    label: 'License State',
                    rules: 'required',
                    options: _vm.licenseStates[index],
                    short: true,
                    clearable: true
                }
            }},on:{"change":function($event){return _vm.updateLicense(item, 'State', $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                name: 'LicenseNumber_' + index,
                type: 'TextInput',
                value: item.Number,
                config: {
                    label: 'License Number',
                    rules: 'required',
                    short: true
                }
            }},on:{"change":function($event){return _vm.updateLicense(item, 'Number', $event)}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"1","cols":"12"}},[_c('v-btn',{staticStyle:{"margin-top":"-8px"},attrs:{"color":"#BBBBBB","type":"button","icon":"","title":"Remove License"},on:{"click":function($event){return _vm.removeLicense(index)}}},[_c('v-icon',[_vm._v("fa-times")])],1)],1)],1)}),1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}},[(_vm.newValue.length >= _vm.max)?_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("You have entered the maximum number of licenses ("+_vm._s(_vm.max)+").")])]):_c('div',[_c('v-btn',{attrs:{"color":"white","type":"button"},on:{"click":_vm.addLicense}},[_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v("  Add License ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }